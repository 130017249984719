.info-box {
  @apply w-full px-4 py-4 text-sm font-medium rounded-xl border mt-3 mb-4 text-slate-500 border-slate-300 bg-slate-50;

  &.success {
    @apply border-emerald-600 bg-emerald-50 text-emerald-600;
  }

  &.danger {
    @apply border-red-600 bg-red-50 text-red-600;
  }

  &.warning {
    @apply border-yellow-600 bg-yellow-50 text-yellow-600;
  }

  &.info {
    @apply border-blue-600 bg-blue-50 text-blue-600;
  }

  &.light {
    @apply border-white bg-white text-slate-600;
  }

  &.dark {
    @apply border-slate-800 bg-slate-800 text-white;
  }

  &.lg {
    @apply h-20;
  }
}
