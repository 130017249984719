@use "@angular/material" as mat;
@use "/src/app/theme/text.scss";
@use "/src/app/theme/buttons.scss";
@use "/src/app/theme/forms.scss";
@use "/src/app/theme/tables.scss";
@use "/src/app/theme/cards.scss";
@use "/src/app/theme/pills.scss";
@use "/src/app/theme/badges.scss";
@use "/src/app/theme/alerts.scss";
@use "/src/app/theme/spinner.scss";
@use "/src/app/theme/skeleton.scss";
@use "/src/app/theme/info-box.scss";


@import "@angular/cdk/overlay-prebuilt.css";
@import "ngx-toastr/toastr";
@import "@angular/cdk/overlay-prebuilt.css";
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  font-size: 1rem;
}

html,
body {
  height: 100%;
  @apply bg-slate-100;
}

body {
  margin: 0;
  font-family: 'Poppins', sans-serif;
}

.header {
  @apply h-[70px];
}

.content-wrapper {
  @apply pb-20 md:pb-10;
  @apply px-2 pt-4 md:pt-6;
  @apply w-full 2xl:w-10/12 mx-auto max-w-7xl;
}

div,
span,
p {
  @apply text-base;
}

/* Hides content visually but keeps it available for screen readers (source: w3.org) */
.visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.first,
.second,
.third,
.fourth {
  @apply bg-slate-50 text-sc-black;
}

.first {
  @apply bg-slate-100;
}

.first {
  input[type="range"] {
    &::-webkit-slider-runnable-track {
      background: #05d394;
    }

    &::-moz-range-track {
      background: #05d394;
    }

    &::-webkit-slider-thumb {
      background-color: #05d394;
    }

    &::-moz-range-thumb {
      background-color: #05d394;
    }
  }
}

.second {
  input[type="range"] {
    &::-webkit-slider-runnable-track {
      background: #7ea5e9;
    }

    &::-moz-range-track {
      background: #7ea5e9;
    }

    &::-webkit-slider-thumb {
      background-color: #7ea5e9;
    }

    &::-moz-range-thumb {
      background-color: #7ea5e9;
    }
  }
}

.third {
  input[type="range"] {
    &::-webkit-slider-runnable-track {
      background: #4d7dd6;
    }

    &::-moz-range-track {
      background: #4d7dd6;
    }

    &::-webkit-slider-thumb {
      background-color: #4d7dd6;
    }

    &::-moz-range-thumb {
      background-color: #4d7dd6;
    }
  }
}

.fourth {
  input[type="range"] {
    &::-webkit-slider-runnable-track {
      background: #1e56c3;
    }

    &::-moz-range-track {
      background: #1e56c3;
    }

    &::-webkit-slider-thumb {
      background-color: #1e56c3;
    }

    &::-moz-range-thumb {
      background-color: #1e56c3;
    }
  }
}
