.pill {
  @apply rounded-full text-xs font-semibold px-3 py-2;

  &.default {
    @apply bg-sc-lightalt text-slate-900;
  }

  &.primary {
    @apply bg-sc-primary/20 text-blue-900;
  }

  &.primary-inverse {
    @apply bg-transparent text-sc-primary border border-sc-primary;
  }

  &.secondary {
    @apply bg-[#D7EEFD] text-sc-primary border border-[#D7EEFD];
  }

  &.danger {
    @apply bg-orange-600/20 text-orange-900;
  }

  &.success {
    @apply bg-emerald-600/20 text-emerald-900;
  }

  &.warning {
    @apply bg-yellow-600/20 text-yellow-900;
  }

  &.lg {
    @apply text-sm;
  }
}
