.badge {
  @apply rounded-md text-xs font-semibold px-1.5 py-0.5 text-white inline-block;

  &.default {
    @apply bg-slate-500;
  }

  &.primary {
    @apply bg-sc-primary;
  }

  &.primary-inverse {
    @apply bg-transparent text-sc-primary border border-sc-primary;
  }

  &.secondary {
    @apply bg-[#D7EEFD] text-sc-primary border border-[#D7EEFD];
  }

  &.danger {
    @apply bg-sc-danger;
  }

  &.success {
    @apply bg-sc-success;
  }

  &.warning {
    @apply bg-sc-warning;
  }

  &.status {
    @apply rounded-full h-3.5 w-3.5;
  }

  &.default-inverse {
    @apply bg-transparent text-slate-500 border border-slate-500;
  }

  &.gasless {
    @apply rounded-full h-3.5 w-3.5 bg-[#D7EEFD];
  }

  &.staking {
    @apply rounded-full h-3.5 w-3.5 bg-sc-primary;
  }
}
