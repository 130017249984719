.card {
  @apply rounded-3xl p-4 md:p-6 border bg-white;

  &.sm {
    @apply pt-2 pb-4;
  }

  &.primary {
    @apply bg-sc-primary text-white;
  }

  &.primary-light {
    @apply bg-[#D7EEFD] text-[#213b66];
  }

  &.success {
    @apply bg-sc-success text-white;
  }

  &.darksuccess {
    @apply bg-sc-darksuccess text-white;
  }

  &.danger {
    @apply bg-sc-danger text-white;
  }

  &.accent {
    @apply bg-slate-50;
  }

  &.twitter {
    @apply bg-[#88C4EF] transition;

    &:hover {
      @apply bg-[#7abcee];
    }
  }
}


.earn-card {
  @apply rounded-3xl p-4 md:p-6 border bg-white;
}
