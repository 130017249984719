
.ngx-toastr {
  @apply bg-gray-50 border border-gray-200 shadow-md rounded-2xl text-slate-500 #{!important};
  @apply hover:shadow-md #{!important};

  &.toast-success {
    background-image: url(/assets/icons/check.svg);

    .toast-progress {
      @apply bg-sc-success #{!important};

    }
  }

  &.toast-info {
    background-image: url(/assets/icons/info.svg);

    .toast-progress {
      @apply bg-sc-primary #{!important};
    }
  }

  &.toast-warning {
    background-image: url(/assets/icons/warning.svg);

    .toast-progress {
      @apply bg-sc-warning #{!important};
    }
  }

  &.toast-error {
    background-image: url(/assets/icons/danger.svg);

    .toast-progress {
      @apply bg-sc-danger #{!important};
    }
  }

  .toast-progress {
    @apply opacity-100 #{!important};
  }
}
