button, a[role="button"] {
  &:not(.nostyle) {
    @apply rounded-full font-semibold duration-200 text-sc-black px-8 text-sm border border-slate-300 bg-white h-12 inline-flex items-center justify-center;
    @apply md:w-auto;
    @apply focus:ring focus:ring-blue-200;
    @apply disabled:bg-slate-100 disabled:text-slate-400 disabled:border-none disabled:hover:bg-slate-100 disabled:hover:text-slate-400;
    @apply hover:bg-slate-100;
  }

  &.primary,
  &.success,
  &.danger,
  &.warning {
    @apply text-white;
  }

  &.primary {
    @apply bg-sc-primary border-sc-primary;
    @apply hover:bg-blue-800 hover:border-blue-800;
  }

  &.success {
    @apply bg-emerald-600 border-emerald-600;
    @apply hover:bg-emerald-700 hover:border-emerald-700;
  }

  &.warning {
    @apply bg-orange-600 border-orange-600;
    @apply hover:bg-orange-700 hover:border-orange-700;
  }

  &.danger {
    @apply bg-sc-danger border-sc-danger;
    @apply hover:bg-red-600 hover:border-red-600;
  }

  &.primary-inverse {
    @apply text-sc-primary bg-white border border-sc-primary;
    @apply hover:bg-slate-100;
  }

  &.danger-inverse {
    @apply text-sc-danger bg-white border border-sc-danger;
    @apply hover:bg-slate-100;
  }

  &.success-inverse {
    @apply text-emerald-700 bg-white border border-emerald-700;
    @apply hover:bg-slate-100;
  }

  &.warning-inverse {
    @apply text-orange-600 bg-white border border-orange-600;
    @apply hover:bg-slate-100;
  }

  &.default-inverse {
    @apply text-slate-100 bg-transparent border border-slate-100;
    @apply hover:bg-slate-100/20;
  }

  &.xs {
    @apply h-6 px-2 text-xs;
  }

  &.sm {
    @apply h-8 px-4;
  }

  &.md {
    @apply h-12 px-6;
  }

  &.lg {
    @apply h-12 px-8;
  }
}
