.table-wrapper {
  @apply overflow-x-auto relative border rounded-3xl;

  table {
    @apply w-full text-left bg-white;

    thead {
      @apply px-2 lg:px-6 sticky top-0 bg-white;

      &::after{
        content:'';
        @apply border-b border-slate-200 absolute bottom-0 left-0 w-full;
      }

      &::before {
        content:'';
        @apply absolute top-0 left-0 w-full;
      }


      th {
        @apply px-2 lg:px-6 py-3 text-slate-500 text-sm font-normal whitespace-nowrap border-b border-slate-200;
      }
    }
    tbody {
      td {
        @apply text-sc-black text-sm px-2 lg:px-6 py-5 whitespace-nowrap;

        &:last-of-type {
          @apply text-right;
        }
      }

      th {
        @apply whitespace-nowrap text-sc-black text-sm px-2 lg:px-6 py-5 font-normal;
      }

      tr {
        @apply border-b hover:bg-slate-50 text-sm;

        &:last-of-type {
          @apply border-none;
        }
      }
    }
  }
}

.rewards-table {
  tbody {
    th {
      @apply py-2;
    }

    tr {
      border: none !important;

      &:nth-of-type(2n) {
        @apply bg-slate-50/50;

        &:hover {
          @apply bg-slate-50/100;
        }
      }
    }
  }
}
