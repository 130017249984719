label:not(.nostyle) {
  @apply select-none text-base;
}

.label:not(.nostyle) {
  @apply block font-medium mb-0.5 mt-3 text-base;
}

.inline-label {
  @apply block font-medium text-base;

  &.right {
    @apply ml-1.5 order-last;
  }

  &.left {
    @apply order-first mr-1.5;
  }

  &.top {
    @apply mb-1.5;
  }
  
  &.disabled {
    @apply text-slate-400;
  }
}

input:not([type="range"]),
textarea,
select {
  @apply my-1 py-2 px-4 rounded-2xl border border-slate-300 resize-none bg-white;
  @apply placeholder:text-slate-500;

  &:disabled {
    @apply bg-slate-100;
    @apply placeholder:text-slate-300;
  }

  @apply hover:border-slate-600;
  @apply active:border-slate-600;
  @apply focus:border-slate-600;
}

// SLIDER
.range {
  -webkit-appearance: none;
  appearance: none;
  background: transparent;
  cursor: pointer;
  position: relative;

  &::-webkit-slider-runnable-track {
    height: 1rem;
    border-radius: 0.5rem;
  }

  &::-moz-range-track {
    height: 1rem;
    border-radius: 0.5rem;
  }

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    margin-top: -4px;
    height: 1.5rem;
    width: 1.5rem;
    border-radius: 0.75rem;
  }

  &::-moz-range-thumb {
    -webkit-appearance: none;
    appearance: none;
    margin-top: -4px;
    height: 1.5rem;
    width: 1.5rem;
    border-radius: 0.75rem;
  }

  &:focus {
    outline: none;

    &::-webkit-slider-thumb {
      border: 1px solid white;
      outline: 1px solid white;
    }

    &::-moz-range-thumb {
      border: 1px solid white;
      outline: 1px solid white;
    }
  }
}

.range {
  @apply transition-all duration-75;
  
}

.slider {
  @apply bg-slate-300/50;
  height: 1rem;
  border-radius: 0.5rem;
  position: relative;
}
// END SLIDER

input,
textarea {
  @apply read-only:bg-transparent;
}

.input-group {
  @apply mt-1 mb-2 relative;
}

/* CHECKBOX */
input[type="checkbox"],
input[type="radio"] {
  @apply border-none overflow-hidden p-0 absolute w-[1px] h-[1px] m-[-1px];
  clip: rect(0 0 0 0);
}

input[type="checkbox"] + label {
  @apply block relative w-12 h-7 rounded-full bg-slate-50 border border-slate-400 cursor-pointer select-none;
  @apply transition-all ease-in-out duration-100;
  @apply hover:border-slate-600;
}

input[type="checkbox"]:focus {
  + label {
    @apply outline outline-blue-600 outline-1 border-blue-600;
  }
}

input[type="checkbox"] + label:before {
  content: "";
  @apply block absolute rounded-full bg-slate-400;
  @apply h-[1.3rem] w-[1.3rem] top-[0.15rem] left-[0.2rem];
  @apply transition-all ease-in-out duration-200;
}

input[type="checkbox"]:disabled + label:before {
  content: "";
  @apply block absolute rounded-full bg-slate-200 border-slate-200;
  @apply h-[1.3rem] w-[1.3rem] top-[0.15rem] left-[0.2rem];
  @apply transition-all ease-in-out duration-200;
}

input[type="checkbox"]:disabled + label {
  @apply text-slate-300;
}

input[type="checkbox"]:checked + label:before {
  @apply bg-sc-primary;
  box-shadow: -2px 0px 5px rgba(0, 0, 0, 0.2);
  left: 1.6em;
}

.form-error {
  @apply text-red-500 text-sm;
}

/* input[type="radio"] + label:before {
  content: "";
  @apply block absolute rounded-full bg-slate-400;
  @apply h-[1.3rem] w-[1.3rem] top-[0.15rem] left-[0.2rem];
  @apply transition-all ease-in-out duration-200;
} */

.suffix {
  @apply absolute right-4 top-16 transform text-sm font-medium text-slate-500;
}

.select-dd {
  @apply rounded-2xl border border-slate-300 bg-white;

  li {
    @apply hover:bg-slate-50 py-2 px-4 overflow-hidden;

    &:first-of-type {
      border-radius: 0.75rem 0.75rem 0 0;
    }

    &:last-of-type {
      border-radius: 0 0 0.75rem 0.75rem;
    }
  }

  .highlighted {
    @apply bg-slate-200;
  }
}

.smart-select {
  @apply my-1 py-2 px-4 rounded-2xl border border-slate-300 resize-none bg-white w-full hover:border-slate-500;
}
