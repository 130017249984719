h1,
h2,
h3,
h4 {
  @apply font-semibold mb-3 text-slate-700;
}

h1 {
  @apply text-xl lg:text-2xl;
}

h2 {
  @apply text-lg lg:text-xl;
}

h3 {
  @apply text-base lg:text-lg;
}

h4 {
  @apply text-sm lg:text-base;
}

a {
  @apply text-sc-primary;
}

details summary > * {
  display: inline-flex;
}

.text-subtitle {
  @apply text-sm lg:text-base;
  @apply text-slate-500;
}

.hint {
  @apply text-sm text-slate-500;
}

$clamps: 1, 2, 3, 4, 5, 6;
@each $clamp in $clamps {
 .line-clamp-#{$clamp} {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: #{$clamp};
 }
}

.circle {
  border-radius: 50%;
  text-align: center;
  height: 1.5rem;
  width: 1.5rem;
  display: inline-block;
  padding-top: 0.1rem;

  &.sm {
    height: 1.2rem;
    width: 1.2rem;
    font-size: 0.75rem !important;
    padding-top: 0.05rem;
  }

  &.asset {
    background-color: #9abbf1;
    color: #2f60ad
  }

  &.collateral {
    background-color: #c7e7ce;
    color: #2b6332;
  }

  &.default {
    background-color: #f0f0f0;
    color: #8c8c8c;
  }
}
