.lds-ring {
  display: inline-block;
  position: relative;
  width: 48px;
  height: 48px;

  div {
    width: 48px;
    height: 48px;
    margin: 6px;
    border: 6px solid rgba(50, 78, 255, 1);
    box-sizing: border-box;
    display: block;
    position: absolute;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: rgba(50, 78, 255, 1) transparent transparent transparent;
  }

  &.xxs {
    width: 14px;
    height: 14px;

    div {
      width: 14px;
      height: 14px;
      margin: 2px;
      border: 3px solid rgba(50, 78, 255, 1);
      box-sizing: border-box;
      display: block;
      position: absolute;
      border-radius: 50%;
      animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
      border-color: rgba(50, 78, 255, 1) transparent transparent transparent;
    }
  }

  &.xs {
    width: 18px;
    height: 18px;

    div {
      width: 18px;
      height: 18px;
      margin: 3px;
      border: 3px solid rgba(50, 78, 255, 1);
      box-sizing: border-box;
      display: block;
      position: absolute;
      border-radius: 50%;
      animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
      border-color: rgba(50, 78, 255, 1) transparent transparent transparent;
    }

  }

  &.sm {
    width: 36px;
    height: 36px;

    div {
      width: 36px;
      height: 36px;
      margin: 5px;
      border: 5px solid rgba(50, 78, 255, 1);
      box-sizing: border-box;
      display: block;
      position: absolute;
      border-radius: 50%;
      animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
      border-color: rgba(50, 78, 255, 1) transparent transparent transparent;
    }
  }

  &.lg {
    width: 60px;
    height: 60px;

    div {
      width: 60px;
      height: 60px;
      margin: 7px;
      border: 7px solid rgba(50, 78, 255, 1);
      box-sizing: border-box;
      display: block;
      position: absolute;
      border-radius: 50%;
      animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
      border-color: rgba(50, 78, 255, 1) transparent transparent transparent;
    }
  }

  &.gray {
    div {
      border-color: #94A3B8 transparent transparent transparent;
    }
  }
}

.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
